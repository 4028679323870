import React from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Field } from '@progress/kendo-react-form';
import {
  FormInput,
  MessageAlert,
  buildNotification,
  GenerateNotification,
} from 'smart-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../Pages/Splash.scss';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../../constants/eventDataTypes';
import {
  getUserByToken,
  getPasswordPolicy,
  changePassword,
} from '../../Services/LoginService';
import {
  UPDATE_PASSWORD_MESSAGE,
  PASSWORD_NOT_MATCH,
  PASSWORD_MESSAGES,
} from '../../../../../constants/notificationMessages';

const NewPassword = ({ setIsLoader }) => {
  const [formState, setFormState] = React.useState({});
  const [formKey, setFormKey] = React.useState(1);
  const queryParams = new URLSearchParams(window.location.search);
  const [userData, setUserData] = React.useState({});
  const [policyPayload, setPolicyPayload] = React.useState({});
  const navigate = useNavigate();
  const token = queryParams.get('token');
  const validatePassword = (value) => {
    if (!value) {
      return PASSWORD_MESSAGES.REQUIRED;
    }

    const regexPattern = new RegExp(policyPayload?.Regex);

    // Check against the dynamic regex
    if (!regexPattern.test(value)) {
      // Validate minimum length
      if (value.length < 8) {
        return PASSWORD_MESSAGES.MIN_LENGTH;
      }

      // Validate at least one uppercase letter
      if (!/[A-Z]/.test(value)) {
        return PASSWORD_MESSAGES.UPPERCASE;
      }

      // Validate at least one digit
      if (!/\d/.test(value)) {
        return PASSWORD_MESSAGES.DIGIT;
      }

      // Validate at least one special character
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        return PASSWORD_MESSAGES.SPECIAL_CHAR;
      }

      // Default message if no other rules are matched
      return PASSWORD_MESSAGES.DEFAULT;
    }

    // All validation rules passed, return empty string to indicate valid input
    return '';
  };

  const validateConfirmPassword = (value, formRenderProps) => {
    const password = formRenderProps.valueGetter('password');
    if (value !== password) {
      return PASSWORD_NOT_MATCH?.title;
    }
  };
  const [alertMessage, setAlertMessage] = React.useState({
    alert: false,
    message: null,
    type: 'danger',
    iconName: 'error',
  });

  const validateUserToken = async (token) => {
    const dataItem = {
      AppId: process.env.APP_ID,
      Token: token,
    };

    try {
      const response = await getUserByToken({
        data: dataItem,
      });

      if (!response?.IsSuccess) {
        setAlertMessage({
          alert: true,
          type: 'danger',
          message: response?.StatusDesc,
          iconName: 'error',
        });
      } else {
        setUserData(response?.Payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkPasswordPolicy = async ({ gatewayId }) => {
    try {
      const response = await getPasswordPolicy({ gatewayId });
      if (response?.IsSuccess) {
        setPolicyPayload(response?.Payload);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (dataItem) => {
    setIsLoader(true);
    dataItem = {
      NewPassword: dataItem?.password,
      ConfirmPassword: dataItem?.cpassword,
      AppId: process.env.APP_ID,
      Token: token,
      Active: true,
    };

    try {
      const response = await changePassword({ data: dataItem });
      if (!response?.IsSuccess) {
        setAlertMessage({
          alert: true,
          type: 'danger',
          message: response?.StatusDesc,
          iconName: 'error',
        });
      } else {
        GenerateNotification(
          buildNotification(UPDATE_PASSWORD_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        navigate('/login');
        setFormKey(formKey + 1);
        setAlertMessage({
          alert: true,
          type: 'success',
          message: UPDATE_PASSWORD_MESSAGE?.description,
          isIcon: false,
          iconName: 'check',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoader(false);
    }
  };

  React.useEffect(() => {
    validateUserToken(token);
  }, []);

  React.useEffect(() => {
    if (userData?.AuthGatewayId) {
      checkPasswordPolicy({ gatewayId: userData?.AuthGatewayId });
    }
  }, [userData]);
  return (
    <>
      {alertMessage?.alert && (
        <MessageAlert
          type={alertMessage?.type}
          message={alertMessage?.message}
          isIcon={true}
          iconName={alertMessage?.iconName}
        />
      )}

      <Form
        initialValues={formState}
        onSubmit={handleSubmit}
        key={formKey}
        render={(formRenderProps) => (
          <FormElement>
            <div>
              <Field
                key={'password'}
                id={'password'}
                name={'password'}
                label={'Password'}
                labelClass={'k-font-bold'}
                inputClass={'k-login-input'}
                type={'password'}
                component={FormInput}
                optional={false}
                validator={validatePassword}
              />
              <Field
                key={'cpassword'}
                id={'cpassword'}
                name={'cpassword'}
                label={'Confirm Password'}
                labelClass={'k-font-bold'}
                inputClass={'k-login-input'}
                type={'password'}
                component={FormInput}
                optional={false}
                validator={(e) => validateConfirmPassword(e, formRenderProps)}
              />
            </div>
            <div
              style={{
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
              className={
                'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'
              }
            >
              <Button
                className='k-w-100'
                themeColor={'dark'}
                type={'submit'}
                rounded={'medium'}
                size={'medium'}
                disabled={!formRenderProps.allowSubmit}
              >
                Create Password
              </Button>
            </div>
            <div className='k-px-3'>
              <p className='signup-link'>
                {' '}
                Already have an account? <Link to='/login'>Log in</Link>
              </p>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};
export default NewPassword;
