import React from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { MessageAlert } from 'smart-react';
import { useAuth } from '../../../Context/AuthContext';
import { callApi } from '../../../../../Utils/Api/Api';
import { TENANT_ID } from '../../../../../constants/applicationConstants';
import { apiRequest } from '../../../../../Middleware/API/apiRequest';
import '../../Pages/Splash.scss';
const dashboardPath = '/';
let tempTenantList = [];
const TenantForm = ({ setIsLoader }) => {
  const [tenant, SetTenant] = React.useState([]);
  const [tenantList, SetTenantLists] = React.useState([]);
  const { updateUser, user, getTokensFromStorage } = useAuth();
  const [alertMessage, setAlertMessage] = React.useState({
    alert: false,
    message: null,
  });
  const filterData = (filter) => {
    const data = tempTenantList.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    SetTenantLists(filterData(event.filter));
  };

  const handleTenant = (event) => {
    SetTenant(event.target.value);
  };
  /**
   * Get list of Tenants
   */
  const getTenants = async () => {
    const baseURL = `${process.env.BASE_URL}/api/Applications/Users/${user.userId}/Tenants`;
    const { accessToken } = getTokensFromStorage();
    const request = {
      baseURL,
      method: 'GET',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
        'X-Application-Id': process.env.APP_ID,
        'X-App-Key': process.env.APP_KEY,
        'X-App-Secret': process.env.APP_SECRET,
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const data = await apiRequest(request);
    const filteredItems = data?.Payload.filter((item) => item.IsWrite);
    tempTenantList = filteredItems;
    SetTenantLists(filteredItems);
  };

  /**
   * get Tenant List
   */
  React.useEffect(() => {
    getTenants();
  }, []);

  const handleSubmit = async (e) => {
    if (tenant?.TenantId) {
      setAlertMessage({
        alert: false,
        message: '',
      });
      localStorage.setItem(TENANT_ID, tenant?.TenantId.toString());
      window.location.replace(dashboardPath);
    } else {
      setAlertMessage({
        alert: true,
        message: 'Please select tenant',
      });
    }
  };
  const defaultItem = {
    TenantName: 'Select Tenant ...',
  };
  return (
    <>
      {alertMessage?.alert && (
        <MessageAlert
          type={'danger'}
          message={alertMessage?.message}
          isIcon={true}
          iconName={'error'}
        />
      )}
      <FormElement>
        <div>
          <DropDownList
            data={tenantList}
            textField='TenantName'
            dataItemKey='TenantId'
            filterable={true}
            onFilterChange={filterChange}
            onChange={handleTenant}
            defaultItem={defaultItem}
          />
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
          className={
            'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'
          }
        >
          <Button
            className='k-w-100'
            themeColor={'dark'}
            type={'button'}
            rounded={'medium'}
            size={'medium'}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </FormElement>
    </>
  );
};
export default TenantForm;
