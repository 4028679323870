import React from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Field } from '@progress/kendo-react-form';
import { FormInput, MessageAlert } from 'smart-react';
import { Link } from 'react-router-dom';
import { sendResetLink, getUserIdByEmail } from '../../Services/LoginService';
import '../../Pages/Splash.scss';
const ForgotPassword = ({ setIsLoader }) => {
  const [formState, setFormState] = React.useState({});
  const [formKey, setFormKey] = React.useState(1);
  const [alertMessage, setAlertMessage] = React.useState({
    alert: false,
    message: null,
    type: 'danger',
    iconName: 'error',
  });

  const sentResetPasswordEmail = async (data) => {
    try {
      const response = await sendResetLink({ data });
      if (!response?.IsSuccess) {
        setAlertMessage({
          alert: true,
          type: 'danger',
          message: response?.StatusDesc,
          iconName: 'error',
        });
      } else {
        setAlertMessage({
          alert: true,
          type: 'success',
          message: 'Password has been sent successfully to your email address.',
          isIcon: false,
          iconName: 'check',
        });
      }
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (dataItem) => {
    setIsLoader(true);
    try {
      const response = await getUserIdByEmail({ data: dataItem });

      if (!response?.IsSuccess) {
        setAlertMessage({
          alert: true,
          type: 'danger',
          message: response?.StatusDesc,
          iconName: 'error',
        });
      } else {
        let data = {
          UserId: response?.Payload,
          EmailAddress: dataItem?.email,
        };
        await sentResetPasswordEmail(data);
      }
      return;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoader(false);
    }
  };
  return (
    <>
      {alertMessage?.alert && (
        <MessageAlert
          type={alertMessage?.type}
          message={alertMessage?.message}
          isIcon={true}
          iconName={alertMessage?.iconName}
        />
      )}

      <Form
        initialValues={formState}
        onSubmit={handleSubmit}
        key={formKey}
        render={(formRenderProps) => (
          <FormElement>
            <div>
              <Field
                key={'email'}
                id={'email'}
                name={'email'}
                label={'Email '}
                labelClass={'k-font-bold'}
                inputClass={'k-login-input'}
                type={'email'}
                component={FormInput}
                optional={false}
              />
            </div>
            <div
              style={{
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
              className={
                'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'
              }
            >
              <Button
                className='k-w-100'
                themeColor={'dark'}
                type={'submit'}
                rounded={'medium'}
                size={'medium'}
              >
                Reset Password
              </Button>
            </div>
            <div className='k-px-3'>
              <p className='signup-link'>
                {' '}
                Already have an account? <Link to='/login'>Log in</Link>
              </p>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};
export default ForgotPassword;
