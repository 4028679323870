import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { errorGenerator, ErrorFallback, Loader } from 'smart-react';
import { apiRequest } from '../../../../Middleware/API/apiRequest';
import { useAuth } from '../../Context/AuthContext';
import { SOCIAL_MEDIA_LOGIN } from '../../../../constants/apiRoutes';
import { login } from '../Utils/CommonUtil';
/**
 * Handle Redirection and UserDetail Data on Redirection
 */
const SocialMediaRedirectComponent = () => {
  const navigate = useNavigate();
  const { updateUser } = useAuth();

  /**
   * Used to get the Tokens
   */
  useEffect(() => {
    // get auth code from url

    const urlParams = new URLSearchParams(window.location.search);
    const redirectAuthCode = urlParams.get('code');
    // get access token with formatted body
    getTokens(generateFormBody(redirectAuthCode));
  }, []);

  // generate form body for request to token endpoint
  function generateFormBody(authCode) {
    const details = {
      code: authCode,
      authGatewayType: 'Google',
    };

    return details;
  }
  // redirect to login in case of error and show error message
  const onLoginError = ({ response }) => {
    errorGenerator({
      alert: true,
      message: JSON.parse(response?.error_description)?.StatusDesc,
    });
    navigate('/login');
  };

  async function getTokens(formBodyDetails) {
    // Default options are marked with *

    const data = await getTokenFromAPI(formBodyDetails);

    if (!data?.IsSuccess) {
      errorGenerator({
        title: 'Data not Valid',
        message: data?.StatusDesc,
      });
      navigate('/');
    } else if (data) {
      const parts = data?.Payload?.UserName?.split(',');
      const username = parts[0];
      const password = parts[1];
      const dataItem = {
        grant_type: 'password',
        AppId: process.env.APP_ID,
        device_id: navigator.userAgent,
        browser: navigator.userAgent,
        username,
        password,
        os: navigator.platform,
        SrcTypeId: 'Google',
      };

      try {
        await login({ dataItem, updateUser, page: 'social', onLoginError });
      } catch (e) {
        // in case of error first reload the page if still not work then redirect to login screen
        const hasReloaded = sessionStorage.getItem('auth_error');

        if (!hasReloaded) {
          sessionStorage.setItem('auth_error', 'true');
          window.location.reload();
        } else {
          sessionStorage.removeItem('auth_error');
          errorGenerator({
            alert: true,
            message: e,
          });
          navigate('/login');
        }
      }
    }
  }

  /**
   * Call KOCHID API to authenticate user
   * @async
   * @param {*} formBodyDetails
   * @returns {Promise<object>} - A promise that resolves the token object.
   */
  async function getTokenFromAPI(formBodyDetails) {
    const baseURL = `${process.env.BASE_URL}/${SOCIAL_MEDIA_LOGIN}?code=${formBodyDetails?.code}&authGatewayType=${formBodyDetails?.authGatewayType}`;

    const request = {
      baseURL,
      method: 'GET',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
        'X-Application-Id': process.env.APP_ID,
      },
    };
    const data = await apiRequest(request);
    return data;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Loader />
      <p>redirecting...</p>
    </ErrorBoundary>
  );
};

export default SocialMediaRedirectComponent;
