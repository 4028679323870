import React from 'react';
import { Loader } from 'smart-react';
import { UN_AUTHORIZED_PAGE_MESSAGE } from '../../../constants/notificationMessages';
/**
 * If user have no permission it'll redirect to this page
 * @returns {JSX.Element} no permission tag
 */
const NoPermission = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <div className='layout-no-permission'>
        <div className='layout-no-permission-wrapper'>
          <div className='layout-no-permission-data'>
            <h6 className='k-py-5 k-text-center k-bg-white'>
              {UN_AUTHORIZED_PAGE_MESSAGE?.description}
            </h6>
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};
export default NoPermission;
