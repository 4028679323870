import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, Loader } from 'smart-react';
import { Button } from '@progress/kendo-react-buttons';
import microsoftIcon from '../../../../../assets/images/sm/microsoft-logo.png';
import { apiRequest } from '../../../../../Middleware/API/apiRequest';
/**
 * Main Login Screen
 * @returns {React.Element} returns main login screen
 */
const LoginWithAzure = ({ setErrorMessage, text }) => {
  const [isLoader, setisLoader] = React.useState(false);
  const login = async () => {
    setisLoader(true);
    const request = {
      baseURL: `${process.env.BASE_URL}/api/AzureADAuth/GetAzureAdAuthUrl/?sourceTypeId=AZURECODE`,
      method: 'GET', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      withCredentials: true,
      headers: {
        'X-Application-Id': process.env.APP_ID,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
    };

    const data = await apiRequest(request, 'false');

    if (data.IsSuccess) {
      window.location.replace(data.Payload);
    } else {
      setErrorMessage('Authentication Failed');
    }
    setisLoader(false);
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {isLoader && (
        <div className='loader-wrapper'>
          <Loader />
        </div>
      )}
      <Button
        fillMode='outline'
        imageUrl={microsoftIcon}
        imageAlt='Microsoft icon'
        className='microsoft-login-btn'
        onClick={login}
      >
        {text}
      </Button>
    </ErrorBoundary>
  );
};

export default LoginWithAzure;
