import { getClientInfo } from 'smart-react';
import {
  postLoginLog,
  postLogin,
  getLookupByType,
  setUserTenant,
  getGlobalConfigById,
} from '../Services/LoginService';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_EXP,
  USER_DATA,
  INDEX_PAGE,
  LOOKUP_TYPES,
  ROLES,
} from '../../../../constants/applicationConstants';
import { dexieCore } from '../../../../Utils/Storage/IndexDB/dexieDb';

/**
 * createLoginLog
 * @param response
 */
export const createLoginLog = async ({ dataItem, response, encodedData }) => {
  let clientInfo = getClientInfo();
  let reqDetails = {
    Request: encodedData.replace(/&password=[^&]+/, ''),
    Response: Object.fromEntries(
      Object.entries(response).filter(
        ([key]) => key !== 'access_token' && key !== 'refresh_token',
      ),
    ),
  };
  let appLog = {
    UserId: response?.error ? dataItem?.username : response?.userId,
    ActivityCode: 'LOGIN',
    RequestAllowed: response?.error ? false : true,
    RequestDisallowReason: response?.error
      ? JSON.stringify({
          StatusCode: JSON.parse(response?.error_description)?.StatusCode,
          StatusDesc: JSON.parse(response?.error_description)?.StatusDesc,
        })
      : '',
    Details: JSON.stringify(reqDetails),
    ...clientInfo,
  };
  postLoginLog({ data: appLog });
};
/**
 * getLookupData
 * @param response
 */
export const getLookupDataForMultipleTypes = async ({ lookupTypes }) => {
  try {
    const lookupPromises = Object.keys(lookupTypes).map((key) =>
      getLookupByType({
        type: lookupTypes[key],
      }).then((response) => {
        localStorage.setItem(
          `${lookupTypes[key]}`,
          JSON.stringify(response?.Payload),
        );
      }),
    );

    await Promise.all(lookupPromises);
  } catch (error) {
    console.error('Error fetching lookup data:', error);
    throw error;
  }
};

/**
 * get Role from Config
 * @param response
 */
export const getRolesFromConfig = async ({ roles }) => {
  try {
    const lookupPromises = Object.keys(roles).map((key) =>
      getGlobalConfigById({
        id: roles[key],
      }).then((response) => {
        localStorage.setItem(
          `${roles[key]}`,
          JSON.stringify(response?.Payload),
        );
      }),
    );

    await Promise.all(lookupPromises);
  } catch (error) {
    console.error('Error fetching lookup data:', error);
    throw error;
  }
};

/**
 * login
 * @param response
 */
export const login = async ({ dataItem, updateUser, page, onLoginError }) => {
  let response = await postLogin({ dataItem, createLoginLog });
  if (response && response?.error) {
    if (page && page !== 'login') {
      onLoginError({ response });
    }
  } else {
    const data = response;

    sessionStorage.setItem(ACCESS_TOKEN, data.access_token);
    sessionStorage.setItem(REFRESH_TOKEN, data.refresh_token);
    sessionStorage.setItem(TOKEN_EXP, data.expires_in);
    sessionStorage.setItem(USER_DATA, JSON.stringify(data));
    updateUser(data);
    if (page === 'social') {
      let configs = [
        {
          ConfigId: ACCESS_TOKEN,
          value: data.access_token,
        },
        {
          ConfigId: REFRESH_TOKEN,
          value: data.refresh_token,
        },
      ];
      await dexieCore.Configurations.bulkPut(configs);
    }

    await Promise.all([
      setUserTenant({ data }),
      getLookupDataForMultipleTypes({ lookupTypes: LOOKUP_TYPES }),
      getRolesFromConfig({ roles: ROLES }),
    ]);
    window.location.replace(INDEX_PAGE);
  }
  return {
    ...response,
    page,
  };
};
