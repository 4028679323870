import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  DataTableHoc,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
import {
  isOrganizationAdminRole,
  isAdminRole,
} from '../../../../Utils/Auth/ValidatePermissions';
import { useAuth } from '../../../Core/Context/AuthContext';
const BaseOrganizationUserDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable'),
);
const OrganizationUserDataTable = DataTableHoc(BaseOrganizationUserDataTable);

/**
 * OrganizationUser main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationUser component.
 */
const OrganizationUser = ({ orgId, moduleName }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  const { user } = useAuth();
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto k-px-4 k-py-2 add-page-section'>
        <OrganizationUserDataTable
          deleteFilter={deleteFilter}
          applyFilter={applyFilter}
          saveFilters={saveFilters}
          moduleName={moduleName}
          dataColumns={dataColumnsArray}
          gridColumnsList={GridColumnsList}
          dataTableName={moduleName}
          setIsReady={setIsReady}
          isReady={isReady}
          rowLayoutConfigName={`${moduleName}.DataGrid.RowLayout`}
          pageLengthConfigName={`${moduleName}.DataGrid.ItemsPerPage`}
          dataSet={userData}
          setDataSet={setUserData}
          initialSort={{
            field: 'id',
            dir: 'desc',
          }}
          isCardonMobileView={false}
          total={process.env.DefaultLimit}
          defaultRowLayout={process.env.RowLayout}
          rowLayouts={ROW_LAYOUTS}
          pageSizes={PAGE_SIZE}
          isOrganizationAdmin={isOrganizationAdminRole(user)}
          isAdmin={isAdminRole(user)}
          orgId={orgId}
        />
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationUser;
